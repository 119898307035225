import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Footer, Header, SplashScreen } from "./components";
import Social from "./socialMedia/media";
import { Home } from "./pages";

function App() {
  return (
    <Fragment>
      <SplashScreen />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <Social />
      <Footer />
    </Fragment>
  );
}

export default App;
