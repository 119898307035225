import React, { useState } from "react";
import "../Hero/Hero.scss";
import { ReactComponent as Dewall } from "../../assets/CarouselLogos/d1.svg";
import Button from "../Button/index";
import Sam from "../../assets/images/sam.svg";
import { ReactComponent as Play } from "../../assets/icons/play.svg";
import Fire from "../../assets/icons/fire-hand.svg";
import { ReactComponent as Left } from "../../assets/icons/left.svg";
import { ReactComponent as Right } from "../../assets/icons/right.svg";

const Hero: React.FC = () => {
  const [toggle, setToggle] = useState(true);

  return (
    <>
      <div className="hero-container">
        <div className="container">
          <div className="title-content">
            <p>
              The first community powered
              <br />
              venture capital framework for building <span>India</span> Globe’s
              first,
            </p>
            <h1>decentralized</h1>
            <h2>
              ecosystem<span>.</span>
            </h2>
          </div>
          <div className="capital-info">
            <div className="info-grid">
              <div className="info-container-left">
                <p>
                  hence araises
                  <br />
                  the name for the firm,
                </p>
                <Dewall />
              </div>
              <div className="info-container-right">
                <p>
                  a valley of prosperity for startups, enterprises, investors
                  and entrepreneurial talents to grow, but this time, this
                  valley from India shall be powered by innovations of
                  distributed ledger technologies, virtual transformation
                  technologies, native digital economies, and quantum
                  coomunications & innovations.
                </p>
              </div>
              <div className="explore-botton">
                <Button>Explore Services</Button>
                <a href="/">Explore Products</a>
              </div>
            </div>

            <div className="ceo-img">
              <img src={Sam} alt="" className="sam" />

              <div className="ceo-container">
                <h5>SAM RAJ . A</h5>
                <p>
                  Chieftain | Executions
                  <br />
                  Devalley Ventures
                </p>
              </div>
            </div>
          </div>
          <div className="explore-btn">
            <Button>Explore Services</Button>
            <a href="/">Explore Products</a>
          </div>
        </div>
        <div className="video-container">
          <div className="video">
            <Play />
          </div>
        </div>
      </div>
      <div className="whitepaper-container">
        <div className="whitepaper-container-left">
          <div className="topic-list">
            <p
              onClick={() => setToggle(true)}
              style={{ borderColor: toggle ? "#00CE53" : "transparent" }}
            >
              The economic problems.
            </p>
            <p
              onClick={() => setToggle(false)}
              style={{ borderColor: !toggle ? "#00ce53" : "transparent" }}
            >
              The talent problems.
            </p>
          </div>
          <div className="problem-container">
            <div className="problem-container-left"></div>
            <div className="problem-container-right">
              <h5>THE TALENT EXPORT PROBLEM.</h5>
              <p>
                These are the problem statements we disvovered with current
                venture capitals in decentralized space and the solutions we
                crafted to safeguard the ecosystem.
              </p>
              <div className="icons">
                <Left className="left" />
                <Right className="right" />
              </div>
            </div>
          </div>
        </div>
        <div className="whitepaper-container-right">
          <div className="inovation-container">
            <h5>
              {" "}
              INNOVATION <br />
              <h6>AND THE REVOLUTION.</h6>
            </h5>
          </div>
          <div className="fire-hand">
            <img src={Fire} alt="" />
          </div>
          <div className="download-btn">
            <Button>Download Paper</Button>
          </div>
          <div className="download-info">
            <p>
              Download our paper to know, how we are solving the
              decentralization oindustries unnoticed and most toughest unsolved
              problems
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
