export const Investors = [
  {
    firstHeader: "Serve before",
    secondHeader: "produce.",
    content:
      " De industries, shall become possible through the conglomeration of the following nine cross vertical firms as the core of this ‘DECENTRALIZATION’ mission,",
  },
  {
    firstHeader: "Excessive borrows",
    secondHeader: "are pitfalls",
    content:
      " De industries, shall become possible through the conglomeration of the following nine cross vertical firms as the core of this ‘DECENTRALIZATION’ mission,",
  },
  {
    firstHeader: "Instinct",
    secondHeader: "after inspirations",
    content:
      " De industries, shall become possible through the conglomeration of the following nine cross vertical firms as the core of this ‘DECENTRALIZATION’ mission,",
  },
  {
    firstHeader: "slow down.",
    secondHeader: "yours is yours.",
    content:
      " De industries, shall become possible through the conglomeration of the following nine cross vertical firms as the core of this ‘DECENTRALIZATION’ mission,",
  },
];

export const Founders = [
  {
    firstHeader: "Excessive borrows",
    secondHeader: "are pitfalls",
    content:
      " De industries, shall become possible through the conglomeration of the following nine cross vertical firms as the core of this ‘DECENTRALIZATION’ mission,",
  },
  {
    firstHeader: "Serve before",
    secondHeader: "produce.",
    content:
      " De industries, shall become possible through the conglomeration of the following nine cross vertical firms as the core of this ‘DECENTRALIZATION’ mission,",
  },
  {
    firstHeader: "slow down.",
    secondHeader: "yours is yours.",
    content:
      " De industries, shall become possible through the conglomeration of the following nine cross vertical firms as the core of this ‘DECENTRALIZATION’ mission,",
  },

  {
    firstHeader: "Instinct",
    secondHeader: "after inspirations",
    content:
      " De industries, shall become possible through the conglomeration of the following nine cross vertical firms as the core of this ‘DECENTRALIZATION’ mission,",
  },
];
