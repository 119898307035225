import React from "react";
import Hero from "../../components/Hero";
import Index from "../../components/PrincipleIronies/Index";
import Video from "../../components/Video/Video"
import "./Home.scss";

const Home: React.FC = () => {
  return (
    <div className="home">
      <Hero />
      <Index/>
   <Video/>
    </div>
  );
};

export default Home;
