export const footerLinks = [
  {
    name: "ACCESS.",
    links: [
      {
        name: "Home",
        to: "/",
      },
      {
        name: "Plans",
        to: "/",
      },
      {
        name: "Services",
        to: "/",
      },
    ],
  },
  {
    name: "KITS.",
    links: [
      {
        name: "Media kit",
        to: "/",
      },
      {
        name: "Investor kit",
        to: "/",
      },
      {
        name: "Partner Kit",
        to: "/",
      },
    ],
  },
  {
    name: "RELATIONS.",
    links: [
      {
        name: "Clients",
        to: "/",
      },
      {
        name: "Investors",
        to: "/",
      },
      {
        name: "Partners",
        to: "/",
      },
    ],
  },
];
