import { ReactComponent as d1 } from "../../assets/CarouselLogos/d1.svg";
import { ReactComponent as d2 } from "../../assets/CarouselLogos/d2.svg";
import { ReactComponent as d3 } from "../../assets/CarouselLogos/d3.svg";
import { ReactComponent as d4 } from "../../assets/CarouselLogos/d4.svg";
import { ReactComponent as d5 } from "../../assets/CarouselLogos/d5.svg";

export const images = [
  { Images: d1 },
  { Images: d2 },
  { Images: d3 },
  { Images: d4 },
  { Images: d5 },
];
