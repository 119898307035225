import React from "react";
import { footerLinks } from "../../data/footerLinks";
import { MailIcon, PhoneIcon, PoweredByLogo } from "../Icons";
import { ReactComponent as FooterLogo } from "../../assets/CarouselLogos/d1.svg";
import "./Footer.scss";

const Footer: React.FC = () => {
  const renderContactUs = (
    <div>
      <div className="mb-15">
        <b className="s-14">For sales & support related to audits,</b>
      </div>
      <p className="flex g-5 s-10 mb-10">
        <MailIcon />
        <span>connect@dewallstreet.capital</span>
      </p>
      <p className="flex g-5 s-10">
        <PhoneIcon />
        <span>+91 44356 72539</span>
      </p>
    </div>
  );

  const renderOnMobile = (
    <div className="footer_wrapper-details_mob">
      <div className="logo">
        <FooterLogo />
        <p className="s-12">
          #1, VOC Street, Mahalaxmi Nagar East Tambaram, Selaiyur Post, Tamil
          Nadu - 600 059, South India
        </p>
      </div>
      {renderContactUs}
    </div>
  );

  const renderOnWindow = (
    <div className="footer_wrapper-details">
      <div className="logo">
        <FooterLogo />
      </div>
      <div className="description">
        <p className="s-12">
          #1, VOC Street, Mahalaxmi Nagar East Tambaram, Selaiyur Post, Tamil
          Nadu - 600 059, South India
        </p>
      </div>
      {renderContactUs}
    </div>
  );

  return (
    <>
      <div className="footer">
        <div className="mx pad">
          <div className="footer_wrapper">
            <div className="footer_wrapper-description">
              <p className="s-12">
                <b>DISCLAIMER :</b> * The legally in corporated firm
                “Dewallstreet Private Limited”, under Mnistry of Corporate
                Affairs (MCA), Registrar of Companies (RoC), Chennai, South
                India which specializes in consultancy services for
                legal,finances and technological security and compliance aspects
                is the only legal entity, as of now which is under multiple
                pivotizations into separate legal entitities of the
                conglomerate, de industries and the parent trust as zero
                community foundation.
              </p>
            </div>
            {renderOnMobile}
            {renderOnWindow}
            <nav>
              {footerLinks.map((data, index) => {
                return (
                  <section key={index.toString()}>
                    <b>{data.name}</b>
                    <ul>
                      {data.links.map((link, j) => (
                        <li key={j.toString()}>
                          <a href={link.to}>{link.name}</a>
                        </li>
                      ))}
                    </ul>
                  </section>
                );
              })}
            </nav>
            <div className="footer_wrapper-terms">
              <a href="/">Terms</a>
              <a href="/">Policies</a>
              <a href="/">Connect</a>
            </div>
          </div>
        </div>
        <div className="border-bottom"></div>
        <div className="flex-center flex-column g-15 copyrights">
          <p className="pad" style={{ textAlign: "center" }}>
            Copyright © 2022. De Industries ® All rights reserved
          </p>
        </div>
      </div>
      <div className="poweredby">
        <div className="mx pad">
          <div className="flex-center">
            <p>Powered by</p>
            <PoweredByLogo />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
