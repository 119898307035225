import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import Access from "../../assets/icons/access.svg";
import Logo from "../../assets/Logos/deinvincible.svg";
import Bell from "../../assets/icons/bell.svg";
import Moon from "../../assets/icons/Moon.svg";
import Menu from "../../assets/icons/MenuBar.svg";

const Header: React.FC = () => {
  const [openClose, setOpenClose] = useState(false);

  useEffect(() => {
    if (openClose) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openClose]);

  const renderLinks = (
    <div className="links">
      <Link to="/">Home</Link>
      <Link to="/">Services</Link>
      <Link to="/">Products</Link>
      <Link to="/">Industries</Link>
    </div>
  );

  const renderIcons = (
    <div className="icon">
      <div className="icons">
        <img src={Bell} alt="" />
        <img src={Moon} alt="" />
      </div>
    </div>
  );

  const renderAccess = (
    <div className="access">
      <img src={Access} alt="" />
    </div>
  );

  return (
    <>
      <header>
        <div className="content">
          <div>
            <img src={Logo} alt="" />
          </div>
          {renderLinks}
          {renderIcons}
        </div>
        {renderAccess}
        <div className="menu" style={{ cursor: "pointer" }}>
          <img onClick={() => setOpenClose((m) => !m)} src={Menu} alt="" />
        </div>
      </header>
      {openClose && (
        <div className="bar">
          <div className="flex">
            {renderIcons}
            {renderAccess}
          </div>
          {renderLinks}
        </div>
      )}
    </>
  );
};

export default Header;
