import React from "react";
import { ReactComponent as Play } from "../../assets/icons/playButton.svg";
import Button from "../Button";
import "../Video/Video.scss";

const Video = () => {
  return (
    <div className="video-container">
      <div className="video-container-left">
        <div className="video">
          <Play />
        </div>
      </div>
      <div className="video-container-right">
        <h4>the chieftains</h4>
        <h2>CHALLENGE</h2>
        <p>
          You could be our next chieftain, and be a heading part of this
          community firm. We’re actively looking for revolutionary generation z
          talents, who can lead business and communities.
        </p>
        <Button style={{ width: "fit-content" }}>Get involved</Button>
      </div>
    </div>
  );
};

export default Video;
