import React, { useState } from "react";
import Carousel from "../Carousel/Carousel";
import Content from "../../components/Carousel/Content";
import { Contents } from "./Content.array";
import { Founders, Investors } from "../../Array/Array";
import "../PrincipleIronies/PrincipleIronies.scss";
import HandPowerFire from "../../assets/images/hand-power-fire.svg";

const Index: React.FC = () => {
  const [founders, setFounders] = useState(true);

  return (
    <>
      <div className="principle-ironies">
        <div className="principle-grid1">
          <div className="principle-content">
            <h4>PRINCIPLE</h4>
            <h5>IRONIES .</h5>
            <p>
              These are the core principles of all the ventures that araise from
              devalley ecosystm
            </p>
          </div>
          <div className="principle-image">
            <img src={HandPowerFire} alt="" />
          </div>
        </div>
        <div className="principle-grid2">
          <div className="principle-header">
            <p onClick={() => setFounders(true)}>
              principles of our sustainable investors
              {founders && <div className="border1"></div>}
            </p>
            <p onClick={() => setFounders(false)}>
              principles of our next generation founders
              {!founders && <div className="border2"></div>}
            </p>
          </div>

          {founders ? (
            <div className="principles">
              {Founders.map((f, i) => {
                return (
                  <div key={i.toString()} className="container">
                    <h5>{f.firstHeader}</h5>
                    <h4>{f.secondHeader}</h4>
                    <p>{f.content}</p>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="principles">
              {Investors.map((f, i) => {
                return (
                  <div key={i.toString()} className="container">
                    <h5>{f.firstHeader}</h5>
                    <h4>{f.secondHeader}</h4>
                    <p>{f.content}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="market-activities">
        <div className="market-grid1">
          <div className="market-content">
            <h6>Foundries & Investments</h6>
            <h4>market.</h4>
            <h3>Activities</h3>
          </div>
          <div>
            <p>
              These are the market activities permitted by metagov. framework
              for foundries and investments market and hence devalley ventures
              falls under the foundries & investments market, the same has to
              abide its market activities to the following code of conducts.
            </p>
          </div>
        </div>
        <Content items={Contents} />
      </div>
      <Carousel />
    </>
  );
};

export default Index;
