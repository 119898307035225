export const Contents = [
  {
    header: "Division",
    number: 21,
    content:
      "These are the market activities permitted by metagov. framework for foundries and investments market and hence devalley ventures falls under the foundries & investments market, the same has to abide its market activities to the following code of conducts.",
  },
  {
    header: "Division",
    number: 22,
    content:
      "These are the market activities permitted by metagov. framework for foundries and investments market and hence devalley ventures falls under the foundries & investments market, the same has to abide its market activities to the following code of conducts.",
  },
  {
    header: "Division",
    number: 23,
    content:
      "These are the market activities permitted by metagov. framework for foundries and investments market and hence devalley ventures falls under the foundries & investments market, the same has to abide its market activities to the following code of conducts.",
  },
  {
    header: "Division",
    number: 24,
    content:
      "These are the market activities permitted by metagov. framework for foundries and investments market and hence devalley ventures falls under the foundries & investments market, the same has to abide its market activities to the following code of conducts.",
  },
];
