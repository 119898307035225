import React from "react";
import vector from "../assets/media/vector.png";
import twitter from "../assets/media/twitter.png";
import instagram from "../assets/media/instagram.png";
import linkedIn from "../assets/media/linkedIn.png";
import connect from "../assets/images/connected.png";
import secured from "../assets/images/securedCode.png";
import rating from "../assets/images/rating.png";
import "../socialMedia/media.scss";
import Button from "../components/Button";

const Media: React.FC = () => {
  return (
    <div>
      <div className="media_main">
        <div className="media_Contant">
          <div className="media">
            <img src={vector} alt="" />
            <div className="para">
              <h5>Governance</h5>
              <p>For governance related forums, connect on discord</p>
            </div>
          </div>
          <div className="media">
            <img src={twitter} alt="" />
            <div className="para">
              <h5>Official Shoutouts</h5>
              <p>For governance related forums, connect on discord</p>
            </div>
          </div>
          <div className="media">
            <img src={instagram} alt="" />
            <div className="para">
              <h5>Motivations.</h5>
              <p>For governance related forums, connect on discord</p>
            </div>
          </div>
          <div className="media">
            <img src={linkedIn} alt="" />
            <div className="para">
              <h5>Governance</h5>
              <p>For governance related forums, connect on discord</p>
            </div>
          </div>
        </div>
        <div className="media_img">
          <img src={connect} alt="" />
          <p>
            GET
            <br /> CONNECTED.
          </p>
        </div>
      </div>
      <div className="metagov_main">
        <div></div>
        <div className="metagov_contant">
          <div className="secure_img">
            <img src={secured} alt="" />
          </div>
          <div className="metago">
            <div className="metagov">
              <h2>
                metagov. <br /> <span>AUTHORIZED</span>
              </h2>
              <Button>Validate</Button>
            </div>
            <div className="meta">
              <img src={rating} alt="" />
              <p>
                Secured with, AAA certification and abides by metagov policies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
