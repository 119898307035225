import React from "react";
import "./Carousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { images } from "../Carousel/Carousel.array";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 890,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Carousel: React.FC = () => {
  return (
    <div className="carousel">
      <div>
        <h3>OUR ECOSYSTEM PARTNERS</h3>
      </div>
      <div className="slider">
        <Slider {...settings} className="slide-track">
          {images.map((f, i) => (
            <div key={i.toString()} className="slide">
              <f.Images />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
