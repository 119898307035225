import React from "react";

const Phone = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2751 10.603V12.6055C14.2758 12.7914 14.2377 12.9754 14.1633 13.1457C14.0888 13.316 13.9796 13.4689 13.8426 13.5946C13.7056 13.7203 13.5439 13.816 13.3678 13.8755C13.1917 13.9351 13.0051 13.9572 12.8199 13.9405C10.766 13.7173 8.79299 13.0154 7.05953 11.8913C5.44677 10.8665 4.07942 9.49911 3.0546 7.88635C1.92654 6.14501 1.22452 4.16243 1.00542 2.09923C0.988738 1.91465 1.01067 1.72861 1.06983 1.55298C1.12899 1.37734 1.22407 1.21594 1.34902 1.07906C1.47397 0.942176 1.62605 0.832813 1.79558 0.757929C1.96512 0.683045 2.14838 0.644282 2.33372 0.644108H4.33618C4.66012 0.640919 4.97416 0.755631 5.21978 0.96686C5.46539 1.17809 5.62582 1.47142 5.67116 1.79219C5.75568 2.43302 5.91242 3.06223 6.1384 3.66783C6.2282 3.90674 6.24764 4.16638 6.1944 4.416C6.14117 4.66562 6.01749 4.89474 5.83803 5.07623L4.99032 5.92393C5.94052 7.59502 7.32416 8.97865 8.99524 9.92886L9.84295 9.08115C10.0244 8.90169 10.2536 8.77801 10.5032 8.72477C10.7528 8.67154 11.0124 8.69098 11.2514 8.78078C11.8569 9.00676 12.4862 9.1635 13.127 9.24802C13.4512 9.29377 13.7474 9.45708 13.959 9.70692C14.1707 9.95675 14.2832 10.2757 14.2751 10.603Z"
        stroke="#787878"
        strokeWidth="0.800985"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Phone;
