import React from "react";

const Mail = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.67097 3.45483H13.3508C14.085 3.45483 14.6857 4.05557 14.6857 4.78981V12.7997C14.6857 13.5339 14.085 14.1346 13.3508 14.1346H2.67097C1.93674 14.1346 1.336 13.5339 1.336 12.7997V4.78981C1.336 4.05557 1.93674 3.45483 2.67097 3.45483Z"
        stroke="#787878"
        strokeWidth="0.800985"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6857 4.78955L8.01087 9.46196L1.336 4.78955"
        stroke="#787878"
        strokeWidth="0.800985"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Mail;
