import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ReactComponent as Next } from "../../assets/images/next-button.svg";

interface IContent {
  items: {
    header: string;
    number: number;
    content: string;
  }[];
}
const setting = {
  infinite: true,
  speed: 500,
  arrow: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Content: React.FC<IContent> = ({ items }) => {
  const ref = useRef<Slider>(null);

  return (
    <div className="market-content-flex">
      <Slider ref={ref} {...setting} className="market-grid2">
        {items.map((f, i) => {
          return (
            <div key={i.toString()}>
              <h4>{f.header}</h4>
              <h3>{f.number}.</h3>
              <p>{f.content}</p>
            </div>
          );
        })}
      </Slider>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => ref.current?.slickNext()}
      >
        <Next />
      </div>
    </div>
  );
};

export default Content;
